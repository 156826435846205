import { Grid } from '@mui/material';
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import CustomButton from 'src/components/CustomButton';
import CustomDialog from 'src/components/CustomDialog';
import CustomInput from 'src/components/CustomInput';
import Colors from 'src/helpers/Colors';
import MainLogo from 'src/images/logo_png.png'
import cookie from 'js-cookie';
import { useDispatch } from 'react-redux'
import allActions from 'src/actions/allActions';
import handelErrors from 'src/helpers/handleErrors';
import { toast } from 'react-toastify';
import CustomFileInput from 'src/components/CustomFileInput';
import SingleCustomAutocomplete from 'src/components/SingleCustomAutocomplete';


const Login = ({ open, onClose }) => {
    const { t, i18n } = useTranslation();
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [isLoginType, setIsLoginType] = useState(true);
    const navigate = useNavigate();
    const [prefixList, setPrefixList] = useState([]);

    const [formData, setFormData] = useState({
        email: "",
        password: "",
        phone_prefix: null,
        phone: "",
        firstname: "",
        lastname: "",
        logo: null,
    })
    const dispatch = useDispatch();
    const handleInputChange = (e) => {
        const { id, name, value, checked, type } = e.target
        setFormData((prev) => ({
            ...prev,
            [id]: value,
        }))
    }


    useEffect(() => {
        if (prefixList.length == 0) {
            const getPrefix = () => {
                axios
                    .get(`${process.env.REACT_APP_API_BASE_URL}/public/getPrefix`, {})
                    .then(function (response) {
                        let data = response.data;
                        setPrefixList(data);
                    })
                    .catch(function (error) {
                        handelErrors(error);
                    });
            }
            getPrefix();
        }
    }, []);

    const loginHandle = () => {
        if (!isLoginType) {
            setIsLoginType(true); return;
        }
        setIsSaveLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/mobileAuth/login`, {
                email: formData.email,
                password: formData.password,
            })
            .then(function (response) {
                const verified_at = response.data.user.email_verified_at;
                const email = response.data.user.email;
                if (verified_at == null) {
                    localStorage.setItem('email', formData.email);
                    onClose();
                    navigate('/Confirmation');
                } else {
                    dispatch(allActions.userActions.setUser(response.data.user))
                    cookie.set("token", response.data.access_token);
                    onClose(true);
                    window.location.reload();
                }
                setIsSaveLoading(false);
            })
            .catch(function (error) {
                handelErrors(error);
                setIsSaveLoading(false);
            });
        return false;
    };
    const handleSignup = () => {
        if (isLoginType) {
            setIsLoginType(false); return
        }
        const formDataObj = new FormData();
        for (const [key, value] of Object.entries(formData)) {
            if (key == "logo") {
                formDataObj.append('logo', value);
            } else {
                if (value && value !== "" && value !== null) {
                    if (Array.isArray(value)) {
                        let arr = [];
                        value.forEach(element => {
                            arr.push(element);
                        });
                        formDataObj.append(`${key}`, JSON.stringify(arr));
                    } else {
                        formDataObj.append(key, value);
                    }
                }
            }

        }
        if (formData.confirmation_password == formData.password) {
            setIsSaveLoading(true);
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASE_URL}/mobileAuth/signup`,
                data: formDataObj,
            })
                .then(function (response) {
                    toast.success(t('signup_successfully'))
                    localStorage.setItem('email', formData.email);
                    setIsSaveLoading(false);
                    onClose();
                    navigate('/Confirmation');

                })
                .catch(function (error) {
                    handelErrors(error);
                    setIsSaveLoading(false);
                });
        } else {
            toast.warning(t('different_password'))
        }
    }

    const resetPassword = () => {
        setIsSaveLoading(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_BASE_URL}/mobileAuth/resetPassword`,
            data: {
                email: formData.email.toLowerCase().trim(),
            },
        })
            .then((res) => {
                setIsSaveLoading(false);
                toast.success(t('forgot_password_mssg'));
            })
            .catch((error) => {
                setIsSaveLoading(false);
                handelErrors(error);
            });

    }
    const handleFileChange = (id, value) => {
        setFormData((prev) => ({
            ...prev,
            [id]: value,
        }))
    }
    const customOnChange = (newValue, name) => {
        setFormData({
            ...formData,
            [name]: newValue,
        })
    }
    return (
        <CustomDialog open={open} maxWidth={'xs'} onClose={onClose}>
            <Grid container spacing={1} sx={{ width: { xs: '98%', md: '90%' }, ml: 'auto', mr: 'auto' }}>
                <Grid item xs={12}>
                    <img src={MainLogo} width={'100%'} height={100} style={{ objectFit: 'contain' }} />
                </Grid>
                {
                    !isLoginType && (
                        <>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <CustomInput
                                    label={t('Firstname')}
                                    id={'firstname'}
                                    variant="outlined"
                                    value={formData.firstname}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <CustomInput
                                    label={t('Lastname')}
                                    id={'lastname'}
                                    variant="outlined"
                                    value={formData.lastname}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SingleCustomAutocomplete
                                    filedName={'phone_prefix'}
                                    label={t('phone_prefix')}
                                    list={prefixList}
                                    value={formData.phone_prefix}
                                    listKey="id"
                                    description="id"
                                    customOnChange={customOnChange}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <CustomInput
                                    label={t('Phone')}
                                    id={'phone'}
                                    variant="outlined"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                        </>
                    )
                }
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <CustomInput
                        label={t('Email')}
                        id={'email'}
                        variant="outlined"
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <CustomInput
                        label={t('Password')}
                        id={'password'}
                        variant="outlined"
                        type={'password'}
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                </Grid>
                {
                    !isLoginType && (
                        <>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <CustomInput
                                    label={t('confirmation_password')}
                                    id={'confirmation_password'}
                                    variant="outlined"
                                    type={'password'}
                                    value={formData.confirmation_password}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ mt: 1 }}>
                                <CustomFileInput
                                    id="logo"
                                    label={'upload'}
                                    multiple={false}
                                    onChange={handleFileChange}
                                />
                            </Grid>
                        </>
                    )
                }
                {
                    isLoginType && (
                        <>
                            <Grid item xs={12}>
                                <div style={{ textAlign: 'center' }}>
                                    <span>{t('forgot_your_password')}</span>
                                    <span style={{ color: Colors.systemThemePrimaryColor, cursor: 'pointer' }} onClick={resetPassword}>{t('reset_here')}</span>
                                </div>
                            </Grid>
                        </>
                    )
                }
                <Grid item xs={12} sx={{ mt: 3 }}>
                    <CustomButton
                        title={t('Login')}
                        variant="contained"
                        onClick={loginHandle}
                        isLoading={isSaveLoading}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                    <CustomButton
                        title={t('Signup')}
                        variant="outlined"
                        onClick={handleSignup}
                        isLoading={isSaveLoading}
                    />
                </Grid>
            </Grid>
        </CustomDialog>
    )
}
export default Login