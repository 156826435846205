import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { FormControl } from '@mui/material'
import { InputAdornment } from '@mui/material'

const CustomInput = ({ id, label, value, onChange, ...props }) => {
    return (
        <FormControl sx={{ width: "100%" }}>
            <TextField
                id={id}
                name={id}
                label={label}
                variant={props.variant ? props.variant : "standard"}
                value={value}
                onChange={onChange}
                type={props.type ? props.type : "text"}
                multiline={props.multiline}
                rows={3}
                color='primary'
                focused={props.focused}
                onWheel={(e) => { e.target.blur() }}
                InputProps={{
                    endAdornment: props.endAdornment && <InputAdornment sx={{ flip: false }} position="end">{props.endAdornment}</InputAdornment>,
                }}
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(93, 89, 255, 1)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(93, 89, 255, 1)',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(93, 89, 255, 1)',
                    },
                }} 
            />
        </FormControl>
    )
}
CustomInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    endAdornment: PropTypes.string,
    multiline: PropTypes.bool,

}

export default CustomInput