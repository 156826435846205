import { MoreVert } from '@mui/icons-material';
import { Divider, Grid, Popover } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import CustomButton from '../CustomButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Colors from 'src/helpers/Colors';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import axios from 'axios';
import cookie from 'js-cookie';
import { toast } from 'react-toastify';
import allActions from 'src/actions/allActions';

const CustomPopover = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const currentUser = useSelector(state => state.currentUser)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id_pop = open ? 'simple-popover' : undefined;

    const shortText = (text) => {
        return text.length > 8
            ? text.slice(0, 8) + '...'
            : text;
    };


    const logout = () => {
        Swal.fire({
            title: 'Please Confirm Your Action ?',
            showCancelButton: true,
            confirmButtonText: 'Logout!',
            icon: 'warning',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${process.env.REACT_APP_API_BASE_URL}/mobileAuth/logout`)
                    .then(res => {
                        axios.defaults.headers.common["Authorization"] = ``;
                        localStorage.clear();
                        cookie.remove("token");
                        dispatch(allActions.userActions.logOut())
                        // window.location.reload();
                        navigate('/');
                    })
                    .catch(e => {
                        toast('Error', {
                            position: "top-right",
                            type: "error",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    });
            }
        })
    }

    return (
        <div aria-describedby={id_pop}>
            <div style={{
                width: '120px'
                , height: '40px'
                , border: '2px solid black'
                , borderColor: Colors.systemThemePrimaryColor
                , borderRadius: '20px'
                , color: Colors.systemThemePrimaryColor
                , display: 'flex'
                , alignItems: 'center'
                , justifyContent: 'space-around'
                , cursor: 'pointer'
            }}
                onClick={handleClick}
            >
                <KeyboardArrowDownIcon color={Colors.systemThemePrimaryColor} />
                <span>{shortText(currentUser.user.firstname + ' ' + currentUser.user.lastname)}</span>
            </div>
            <Popover
                id={id_pop}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{
                    width: '100%',
                    height: '100%',
                    marginTop: '44px',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div style={{ width: '120px', padding: '1px', borderRadius: '5px' }}>
                    <Grid container spacing={1} sx={{ p: 1, borderColor: Colors.systemThemePrimaryColor, borderRadius: '5px' }}>
                        <Grid item xs={12} style={{ color: Colors.systemThemePrimaryColor, cursor: 'pointer' }}>
                            <span onClick={() => navigate('/MyCars')}>{t('my_cars')}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} style={{ color: Colors.systemThemePrimaryColor, cursor: 'pointer' }}>
                            <span onClick={() => navigate('/MyActivity')}>{t('my_activity')}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} style={{ color: Colors.systemThemePrimaryColor, cursor: 'pointer' }}>
                            <span onClick={logout}>{t('Logout')}</span>
                        </Grid>
                    </Grid>
                </div>
            </Popover>
        </div>
    )
}
export default CustomPopover
