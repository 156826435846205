import React from 'react'
import Colors from 'src/helpers/Colors'

const CustomPagination = ({ data,number,active,onChange }) => {
    return (
        <div style={{
            width: '30px'
            , height: '30px'
            , border: '2px solid black'
            , display: 'flex'
            , alignItems: 'center'
            , justifyContent: 'center'
            ,borderRadius:'50%'
            ,fontWeight:'bold'
            ,cursor:'pointer'
            ,color: Colors.systemThemePrimaryColor 
            ,borderColor:active ? Colors.systemThemePrimaryColor : 'white'
        }} onClick={()=>onChange(data.id)}>
            {number}
        </div>
    )
}
export default CustomPagination