import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, FormControlLabel, TextField } from '@mui/material'
import { FormControl } from '@mui/material'
import { InputAdornment } from '@mui/material'

const CustomCheckbox = ({ id, label, value, onChange,name, ...props }) => {
    return (
        <FormControl sx={{ width: "100%" }}>
            <FormControlLabel
                control={
                    <Checkbox checked={value} onChange={onChange} id={id} name={name} />
                }
                label={label}
            />
        </FormControl>
    )
}
CustomCheckbox.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
}

export default CustomCheckbox