import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import routes from '../routes';
import { CircularProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import CustomHeader from './CustomHeader/CustomHeader';
import AuthRoutes from 'src/AuthRoutes';

const AppContent = () => {
    const currentUser = useSelector((state) => state.currentUser);
    return (
        <div className='App'>
            <CustomHeader />
            <Routes>
                {
                    routes.map((route, idx) => {
                        return (
                            route.component && (
                                <>
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact={route.exact}
                                        // name={route.name}
                                        element={route.component}
                                    /></>
                            )
                        )
                    })
                }
                {currentUser?.loggedIn === true &&
                    AuthRoutes.map((authRoute, authIdx) => {
                        return (
                            authRoute.component && (
                                <Route
                                    key={authIdx}
                                    path={authRoute.path}
                                    exact={authRoute.exact}
                                    //   name={authRoute.name}
                                    element={authRoute.component}
                                />
                            )
                        );
                    })}
            </Routes>
        </div>
    )
}
export default AppContent