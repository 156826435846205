import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '@mui/material'
import { Button } from '@mui/material'
import { CheckCircle, FileDownload, UploadFile } from '@mui/icons-material'

const CustomFileInput = ({ id, label, value, onChange, ...props }) => {
    const [fileUploaded, setFileUploaded] = useState(0);

    const handleChange = (e)=>{
        e.preventDefault();
        if(props.multiple){
            if (e.target.files[0]) {
                setFileUploaded(e.target.files.length)
                onChange(id,[...e.target.files]);
                return
            }
            setFileUploaded(0)
            onChange(id,0);
        }else{
            if (e.target.files[0]) {
                setFileUploaded(e.target.files.length)
                onChange(id,e.target.files[0]);
                return
            }
            setFileUploaded(0)
            onChange(id,0);
        }
        
    }

    return (
        <FormControl sx={{ mx: "5px", my: "5px" }}>
            <div>
                <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    startIcon={<UploadFile />}
                    // endIcon={fileUploaded > 0 && <CheckCircle />}
                    endIcon={
                        <>
                            {fileUploaded > 0 && <CheckCircle />}
                            {props.endIconClickEvent && 
                            <FileDownload onClick={(e) => {
                                props.endIconClickEvent(e)
                            }} />}
                        </>
                    }
                >
                    {label}
                    <input
                        type="file"
                        id={id}
                        name={id}
                        hidden
                        multiple={props.multiple}
                        onChange={(e) => handleChange(e)}
                    />
                </Button>

            </div>
        </FormControl>
    )
}
CustomFileInput.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    endAdornment: PropTypes.string,
    multiline: PropTypes.bool,

}

export default CustomFileInput