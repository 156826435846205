import logo from './logo.svg';
import './App.css';
import { ToastContainer } from "react-toastify";
import { createBrowserHistory } from "history";
import {
  BrowserRouter,
  HashRouter,
  Routes as ReactRoutes,
  Route,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppContent from './components/AppContent';
import createCache from '@emotion/cache';
import { useTranslation } from 'react-i18next';
import { CacheProvider } from '@emotion/react';
import { Suspense, useEffect } from 'react';
import CustomHeader from './components/CustomHeader/CustomHeader';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import allActions from './actions/allActions';
import cookie from 'js-cookie';

// Create ltr cache
const cacheLtr = createCache({
  key: 'muiltr',
});
const hist = createBrowserHistory();
const ltrTheme = createTheme({
  direction: 'ltr',
  palette: {
    primary: {
      main: "rgba(93, 89, 255, 1)",
      dark: "#000000",
    },
    secondary: {
      main: "#ffffff",
      dark: "#000000",
    },
  },
});

const Loader = () => (
  <p>LOADING</p>
);


function App() {
  const { t, i18n } = useTranslation();
  const token = cookie.get("token");
  const dispatch = useDispatch();
  useEffect(() => {
    const vaildateUser = async () => {
      // verify a token symmetric
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios.get(`${process.env.REACT_APP_API_BASE_URL}/mobileAuth/user-profile`)
        .then((res) => {
          dispatch(allActions.userActions.setUser(res.data))

        }).catch((err) => {
          axios.defaults.headers.common["Authorization"] = ``;
          localStorage.clear();
          cookie.remove("token");
          dispatch(allActions.userActions.logOut())
        });
    }
    if (token) {
      vaildateUser();
    }
    else {
      localStorage.clear();
    }
  }, [token]);
  return (
    <CacheProvider value={cacheLtr}>
      <ThemeProvider theme={ltrTheme}>
        <BrowserRouter history={hist}>
          <Suspense fallback={<Loader />}>
            <ToastContainer />
            <AppContent />
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
