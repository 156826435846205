import React, { useState, useEffect } from 'react'
import { AppBar, CircularProgress, Grid, IconButton, Pagination, Toolbar, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next';
import FilterForm from './FilterForm';
import CustomCard from './CustomCard';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import handelErrors from 'src/helpers/handleErrors';
import CustomPagination from 'src/components/CustomPagination';

const Home = () => {
    const { t, i18n } = useTranslation();
    localStorage.setItem('isAdmin', false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);

    const [filterForm, setFilterForm] = useState({
        model_id: null,
        brand_id: null,
        location_id: null,
        city_id: null,
        from_year: null,
        to_year: null,
        type_id: null,
        type_category_id: null,
        from_price: '',
        to_price: '',
        from_kms: '',
        to_kms: '',
        lot_number: '',
        address: '',
        user_id: null,
        fuel_type_ids: [],
        transmission_type_ids: [],
    });

    const getData = () => {
        setIsLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_BASE_URL}/public/getCars`, {
                params: {
                    from_year: filterForm.from_year,
                    to_year: filterForm.to_year,
                    from_km: filterForm.from_kms,
                    to_km: filterForm.to_kms,
                    from_price: filterForm.from_price,
                    to_price: filterForm.to_price,
                    brand_id: filterForm.brand_id,
                    model_ids: [filterForm.model_id],
                    location_id: filterForm.location_id,
                    city_id: filterForm.city_id,
                    type_id: filterForm.type_id,
                    user_id: filterForm.user_id,
                    lot_number: filterForm.lot_number,
                    address: filterForm.address,
                    type_category_id: filterForm.type_category_id,
                    fuel_type_ids: filterForm.fuel_type_ids,
                    transmission_type_ids: filterForm.transmission_type_ids,
                    page: currentPage,
                }
            })
            .then(function (response) {
                let data = response.data.data;
                setData(data);
                setTotalPages(response.data.total_pages);
                setIsLoading(false);
            })
            .catch(function (error) {
                handelErrors(error);
                setIsLoading(false);
            });
    }
    useEffect(() => {
        if (filterForm.from_kms > 0
            || filterForm.to_kms > 0
            || filterForm.from_price > 0
            || filterForm.to_price > 0
            || filterForm.address != ''
            || filterForm.location_id != '') {
            const timer = setTimeout(() => {
                getData();
            }, 700);

            return () => clearTimeout(timer);
        }
        else {
            getData()
        }
    }, [filterForm,currentPage]);
    const handleInputChange = (e) => {
        const { id, name, value, checked, type } = e.target
        setFilterForm((prev) => ({
            ...prev,
            [id]: value,
        }))
    }
    const customOnChange = (newValue, name) => {
        setFilterForm({
            ...filterForm,
            [name]: newValue,
        })
    }
    const handleCheckboxChange = (event) => {
        const { id, name, checked } = event.target;
        if (checked) {
            setFilterForm((prev) => ({
                ...prev,
                [name]: [...prev[name], id],
            }));
        } else {
            setFilterForm((prev) => ({
                ...prev,
                [name]: prev[name].filter((v) => v !== id),
            }));
        }
    };

    const handleClickCard = (id) => {
        localStorage.setItem('isAdmin', false);
        navigate(`../Details/${id}`);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_API_BASE_URL}/public/IncrementClicks`,
            data: { id: id },
        })
            .then((response) => {
            })
            .catch((error) => {
                handelErrors(error)
            });
    }
    const onChangePage = (event,value) => {
        setCurrentPage(value - 1);
    }

    return (
        <Grid container spacing={1} style={{ marginTop: '20px' }}>
            <Grid item xs={12} md={4} lg={3.4}>
                <FilterForm
                    handleCheckboxChange={handleCheckboxChange}
                    handleInputChange={handleInputChange}
                    filterForm={filterForm}
                    customOnChange={customOnChange}
                />
            </Grid>
            <Grid item xs={12} md={8} lg={8.4}>
                <Grid container spacing={1}>
                    {
                        isLoading ? <CircularProgress />
                            :
                            data.length == 0 ?
                                <div style={{
                                    width: '50%'
                                    , height: '100px'
                                    , marginLeft: 'auto'
                                    , marginRight: 'auto'
                                    , display: 'flex'
                                    , alignItems: 'center'
                                    , justifyContent: 'center'
                                }}>{t('no_data')}</div>
                                :
                                data.map((e, index) => (
                                    <Grid item xs={12}>
                                        <div onClick={() => handleClickCard(e.id)}><CustomCard data={e} /></div>
                                    </Grid>
                                ))
                    }
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* {
                            totalPages.map((e, index) => (
                                <CustomPagination
                                    number={e.value}
                                    key={index}
                                    active={e.value == (currentPage + 1) ? true : false}
                                    onChange={onChangePage}
                                    data={e}
                                />
                            ))
                        } */}
                        <Pagination count={totalPages} onChange={onChangePage} color='primary'/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export default Home
