import React, { useState, useEffect } from 'react'
import Style from './style.css'
import { AppBar, Box, Button, Container, Grid, Icon, IconButton, Toolbar, Typography, ThemeProvider } from '@mui/material'
import MainLogo from 'src/images/logo_png.png'
import CustomSearch from '../CustomSearch'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';
import CustomButton from '../CustomButton'
import Login from 'src/views/Login/Login'
import { useSelector } from 'react-redux'
import CustomPopover from './CustomPopover'
import Colors from 'src/helpers/Colors'

const CustomHeader = () => {
    const { t, i18n } = useTranslation();
    const currentUser = useSelector(state => state.currentUser)
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <AppBar position="sticky" sx={{ backgroundColor: "secondary.main", zIndex: 1, boxShadow: 'none', height: '50px', marginBottom: '30px' }}>
            <Container maxWidth="xl" style={{ height: '50px' }}>
                <Toolbar disableGutters>
                    <Box sx={{ mr: { xs: 2, md: 5 } }}>
                        <img src={MainLogo} width={80} height={'100%'} onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <Box sx={{ width: '100%', height: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
                            <Box sx={{ color: Colors.systemThemePrimaryColor, paddingRight: 5, fontSize: 16, cursor: 'pointer' }}
                            >
                                <span onClick={() => navigate('/Support')}>Support</span>
                            </Box>
                            {/* <CustomSearch label={t('Search')} /> */}
                        </Box>
                    </Typography>
                    <Typography>
                        {
                            currentUser.loggedIn === true
                                ?
                                <CustomPopover />
                                :
                                <CustomButton
                                    title={t('Login')}
                                    variant="contained"
                                    onClick={handleOpen}
                                />
                        }

                    </Typography>
                </Toolbar>
            </Container>
            <Login open={open} onClose={handleClose} />
        </AppBar>
    )
}
export default CustomHeader