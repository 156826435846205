import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import SingleCustomAutocomplete from 'src/components/SingleCustomAutocomplete';
import { CircularProgress, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomInput from 'src/components/CustomInput';
import CustomCheckbox from 'src/components/CustomCheckbox';
import axios from 'axios';
import handelErrors from 'src/helpers/handleErrors';
import { getYears } from 'src/helpers/functions';
import SingleCustomAutocompleteServerSide from 'src/components/SingleCustomAutocompleteServerSide';

const drawerWidth = 300;

function FilterForm({ handleCheckboxChange, handleInputChange, customOnChange, filterForm }) {
    const { t, i18n } = useTranslation();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isModelsLoading, setIsModelLoading] = useState(false);
    const [isCategoryLoading, setIsCategoryLoading] = useState(false);
    const [isFuelLoading, setIsFuelLoading] = useState(false);
    const [isTranmissionLoading, setIsTransmissionLoading] = useState(false);
    const [locationsList, setLocationsList] = useState([]);
    const [modelsList, setModelsList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [typesList, setTypesList] = useState([]);
    const [typeCategories, setTypeCategories] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [transmissionType, setTransmissionType] = useState([]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    useEffect(() => {
        if (locationsList.length == 0) {
            const getLocations = () => {
                axios
                    .get(`${process.env.REACT_APP_API_BASE_URL}/MasterData/GeneralSettings/locations/get`, {
                    })
                    .then(function (response) {
                        let data = response.data;
                        setLocationsList(data);
                    })
                    .catch(function (error) {
                        handelErrors(error);
                    });
            }
            getLocations();
        }
        if (brandsList.length == 0) {
            const getBrands = () => {
                axios
                    .get(`${process.env.REACT_APP_API_BASE_URL}/MasterData/GeneralSettings/brands/get`, {
                    })
                    .then(function (response) {
                        let data = response.data;
                        setBrandsList(data);
                    })
                    .catch(function (error) {
                        handelErrors(error);
                    });
            }
            getBrands();
        }
        if (modelsList.length == 0) {
            const getModels = () => {
                axios
                    .get(`${process.env.REACT_APP_API_BASE_URL}/MasterData/GeneralSettings/models/get`, {
                    })
                    .then(function (response) {
                        let data = response.data;
                        setModelsList(data);
                    })
                    .catch(function (error) {
                        handelErrors(error);
                    });
            }
            getModels();
        }
        if (typeCategories.length == 0) {
            const getCategories = () => {
                axios
                    .get(`${process.env.REACT_APP_API_BASE_URL}/MasterData/GeneralSettings/typeCategories/get`, {
                    })
                    .then(function (response) {
                        let data = response.data;
                        setTypeCategories(data);
                    })
                    .catch(function (error) {
                        handelErrors(error);
                    });
            }
            getCategories();
        }
        if (citiesList.length == 0) {
            const getCities = () => {
                axios
                    .get(`${process.env.REACT_APP_API_BASE_URL}/MasterData/GeneralSettings/cities/get`, {
                    })
                    .then(function (response) {
                        let data = response.data;
                        setCitiesList(data);
                    })
                    .catch(function (error) {
                        handelErrors(error);
                    });
            }
            getCities();
        }
        if (typesList.length == 0) {
            const getTypes = () => {
                axios
                    .get(`${process.env.REACT_APP_API_BASE_URL}/MasterData/GeneralSettings/types/get`, {
                    })
                    .then(function (response) {
                        let data = response.data;
                        setTypesList(data);
                    })
                    .catch(function (error) {
                        handelErrors(error);
                    });
            }
            getTypes();
        }
        if (fuelTypes.length == 0) {
            const getFuelTypes = () => {
                setIsFuelLoading(true);
                axios
                    .get(`${process.env.REACT_APP_API_BASE_URL}/MasterData/GeneralSettings/fuelTypes/get`, {
                    })
                    .then(function (response) {
                        let data = response.data;
                        setFuelTypes(data);
                        setIsFuelLoading(false);
                    })
                    .catch(function (error) {
                        handelErrors(error);
                        setIsFuelLoading(false);
                    });
            }
            getFuelTypes();
        }
        if (transmissionType.length == 0) {
            const getTransmissionType = () => {
                setIsTransmissionLoading(true);
                axios
                    .get(`${process.env.REACT_APP_API_BASE_URL}/MasterData/GeneralSettings/transmissionTypes/get`, {
                    })
                    .then(function (response) {
                        let data = response.data;
                        setTransmissionType(data);
                        setIsTransmissionLoading(false);
                    })
                    .catch(function (error) {
                        handelErrors(error);
                        setIsTransmissionLoading(false);
                    });
            }
            getTransmissionType();
        }
    }, []);

    useEffect(() => {
        if (filterForm.brand_id) {
            const getModel = () => {
                setIsModelLoading(true);
                axios
                    .get(`${process.env.REACT_APP_API_BASE_URL}/MasterData/GeneralSettings/models/getByBrand-${filterForm.brand_id}`, {
                    })
                    .then(function (response) {
                        let data = response.data;
                        setModelsList(data);
                        setIsModelLoading(false);
                    })
                    .catch(function (error) {
                        handelErrors(error);
                        setIsModelLoading(false);
                    });
            }
            getModel();
        }

    }, [filterForm.brand_id])


    // useEffect(() => {
    //     if (filterForm.type_id) {
    //         const getCategories = () => {
    //             setIsCategoryLoading(true);
    //             axios
    //                 .get(`${process.env.REACT_APP_API_BASE_URL}/MasterData/GeneralSettings/typeCategories/getByType-${filterForm.type_id}`, {
    //                 })
    //                 .then(function (response) {
    //                     let data = response.data;
    //                     setTypeCategories(data);
    //                     setIsCategoryLoading(false);
    //                 })
    //                 .catch(function (error) {
    //                     handelErrors(error);
    //                     setIsCategoryLoading(false);
    //                 });
    //         }
    //         getCategories();
    //     }
    // }, [filterForm.type_id])

    const drawer = (
        <div style={{ padding: '10px' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t('Dealer')}</span>
                </Grid>
                <Grid item xs={12}>
                    <SingleCustomAutocompleteServerSide
                        filedName="user_id"
                        label={t('Dealer')}
                        value={filterForm.user_id}
                        listKey="user_id"
                        description="full_name"
                        customOnChange={customOnChange}
                        // apiEndPoint={`/masterData/companies/getDataBySearchQuery`}
                        apiEndPoint={`/public/getCustomersBySearch`}
                    />
                </Grid>
                <Grid item xs={12}>
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t('Location')}</span>
                </Grid>
                <Grid item xs={12}>
                    <SingleCustomAutocomplete
                        filedName={'city_id'}
                        label={t('City')}
                        list={citiesList}
                        value={filterForm.city_id}
                        listKey="id"
                        description="name"
                        customOnChange={customOnChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SingleCustomAutocomplete
                        filedName={'location_id'}
                        label={t('Location')}
                        list={locationsList}
                        value={filterForm.location_id}
                        listKey="id"
                        description="name"
                        customOnChange={customOnChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        label={t('lot_number')}
                        id={'lot_number'}
                        variant="filled"
                        focused
                        value={filterForm.lot_number}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomInput
                        label={t('Address')}
                        id={'address'}
                        variant="filled"
                        focused
                        value={filterForm.address}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t('Year')}</span>
                </Grid>
                <Grid item xs={12}>
                    <SingleCustomAutocomplete
                        filedName={'from_year'}
                        label={t('from_year')}
                        list={getYears()}
                        value={filterForm.from_year}
                        listKey="id"
                        description="name"
                        customOnChange={customOnChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SingleCustomAutocomplete
                        filedName={'to_year'}
                        label={t('to_year')}
                        list={getYears()}
                        value={filterForm.to_year}
                        listKey="id"
                        description="name"
                        customOnChange={customOnChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t('Details')}</span>
                </Grid>
                <Grid item xs={12}>
                    <SingleCustomAutocomplete
                        filedName={'brand_id'}
                        label={t('Brand')}
                        list={brandsList}
                        value={filterForm.brand_id}
                        listKey="id"
                        description="name"
                        customOnChange={customOnChange}
                    />
                </Grid>
                {
                    isModelsLoading ? <CircularProgress />
                        :
                        <Grid item xs={12}>
                            <SingleCustomAutocomplete
                                filedName={'model_id'}
                                label={t('Model')}
                                list={modelsList}
                                value={filterForm.model_id}
                                listKey="id"
                                description="name"
                                customOnChange={customOnChange}
                            />
                        </Grid>
                }
                <Grid item xs={12}>
                    <Divider />
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t('Type')}</span>
                </Grid>
                <Grid item xs={12}>
                    <SingleCustomAutocomplete
                        filedName={'type_id'}
                        label={t('Type')}
                        list={typesList}
                        value={filterForm.type_id}
                        listKey="id"
                        description="name"
                        customOnChange={customOnChange}
                    />
                </Grid>
                {/* {
                    isCategoryLoading ?
                        <CircularProgress />
                        :
                        <Grid item xs={12}>
                            <SingleCustomAutocomplete
                                filedName={'type_category_id'}
                                label={t('Category')}
                                list={typeCategories}
                                value={filterForm.type_category_id}
                                listKey="id"
                                description="name"
                                customOnChange={customOnChange}
                            />
                        </Grid>
                } */}
                <Grid item xs={12}>
                    <Divider />
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t('Price')}</span>
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        label={t('From')}
                        id={'from_price'}
                        variant="filled"
                        focused
                        value={filterForm.from_price}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        label={t('To')}
                        id={'to_price'}
                        variant="filled"
                        focused
                        value={filterForm.to_price}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t('KMS')}</span>
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        label={t('From')}
                        id={'from_kms'}
                        variant="filled"
                        focused
                        value={filterForm.from_kms}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CustomInput
                        label={t('To')}
                        id={'to_kms'}
                        variant="filled"
                        focused
                        value={filterForm.to_kms}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t('fuel_type')}</span>
                </Grid>
                <Grid item xs={12}>
                    {
                        isFuelLoading ? <CircularProgress />
                            :
                            (
                                fuelTypes.map((e, index) => (
                                    <span key={index}>
                                        <CustomCheckbox
                                            label={e.name}
                                            id={e.id}
                                            name={'fuel_type_ids'}
                                            value={filterForm.fuel_type_ids.includes(`${e.id}`)}
                                            onChange={handleCheckboxChange}
                                        />
                                    </span>
                                ))
                            )
                    }

                </Grid>
                <Grid item xs={12}>
                    <Divider />
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{t('transmission_type')}</span>
                </Grid>
                <Grid item xs={12}>
                    {
                        isTranmissionLoading ? <CircularProgress />
                            :
                            (
                                transmissionType.map((e, index) => (
                                    <span key={index}>
                                        <CustomCheckbox
                                            label={e.name}
                                            id={e.id}
                                            name={'transmission_type_ids'}
                                            value={filterForm.transmission_type_ids.includes(`${e.id}`)}
                                            onChange={handleCheckboxChange}
                                        />
                                    </span>
                                ))
                            )
                    }
                </Grid>
            </Grid>
        </div>
    );
    return (
        <Box sx={{ display: 'flex', marginBottom: '30px' }}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'block', md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
            <Box
                component="nav"
                sx={{ width: { sm: '100%' }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Box sx={{ display: { xs: "none", md: 'block' } }}
                    style={{
                        width: '80%'
                        // ,border:'1px solid grey'
                        , borderRadius: '5px'
                        , minHeight: '100px'
                    }}>
                    {drawer}
                </Box>
            </Box>
        </Box>
    );
}


export default FilterForm;
