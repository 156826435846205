import React from 'react';
import Home from './views/Home/Home'
const CarDetails = React.lazy(() => import("./views/CarDetails/CarDetails"));
const Confirmation = React.lazy(() => import("./views/Login/Confirmation"));
const MyCars = React.lazy(() => import("./views/MyCars/MyCars"));
const MyActivity = React.lazy(() => import("./views/Reports/MyActivity/MyActivity"));

const AuthRoutes = [
    { path: '/', exact: true, component: <Home /> },
    { path: "*", exact: true, component: <Home /> },
    { path: "/MyCars", exact: true, component: <MyCars /> },
    { path: "/MyCars/Details/:id", exact: true, component: <CarDetails /> },
    { path: "/MyActivity", exact: true, component: <MyActivity /> },
];
export default AuthRoutes