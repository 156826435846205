import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';


const CustomDialog = ({ open, onClose, children, ...props }) => {
    return (
        <Dialog
            maxWidth={props.maxWidth ? props.maxWidth : 'md'}
            fullWidth
            open={open}
            onClose={onClose}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{mt:5}}>
                {children}
            </DialogContent>
        </Dialog>
    )
}
export default CustomDialog