import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardMedia, Divider, Grid, IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainLogo from 'src/images/logo_png.png'
import Colors from 'src/helpers/Colors';
import SpeedIcon from '@mui/icons-material/Speed';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const CustomCard = ({ data }) => {
    const currentUser = useSelector(state => state.currentUser)
    const { t, i18n } = useTranslation();
    const isAdmin = localStorage.getItem('isAdmin');

    const shortenDescription = (description) => {
        return description.length > 150
            ? description.slice(0, 150) + '...'
            : description;
    };
    return (
        <Card
            variant="outlined"
            sx={{
                mb: 2,
                width: { xs: '100%', sm: 'auto' },
                display: 'flex',
                cursor: 'pointer',
                flexDirection: { xs: 'column', sm: 'row' },
                gap: 2,
                // borderColor: Colors.systemThemePrimaryColor
            }}
        >
            <Grid container>
                <Grid item xs={12} md={12} lg={5} sx={{ height: 300 }}>
                    <img
                        src={`${process.env.REACT_APP_API_BASE_URL_FILE}/images/${data.default_image}`}
                        alt='car'
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={8} lg={4} >
                    <Box sx={{ ml: 2, mt: 3 }}>
                        <Typography sx={{ fontSize: 24, fontWeight: 'bold', textTransform: 'capitalize' }} color="primary" gutterBottom>
                            CFA {data.price > 0 ? data.price.toLocaleString() : 0}
                        </Typography>
                        <Typography sx={{ fontSize: 20, textTransform: 'capitalize' }} gutterBottom>
                            {data.year} {data.brand_name} {data.model_name}
                        </Typography>
                        <Typography >
                            {
                                data.short_description ?
                                    shortenDescription(data.short_description) :
                                    ''
                            }
                        </Typography>
                        <Typography sx={{ mt: 1, display: 'flex', flexDirection: 'row' }}>
                            {
                                data.kms && (
                                    <>
                                        <SpeedIcon fontSize='small' />
                                        <span style={{ marginLeft: '2px', fontSize: '14px', fontWeight: 'bold' }}>{data.kms}</span>
                                        <span style={{ marginLeft: '2px', fontSize: '14px', fontWeight: 'bold' }}>{t('km')}</span>
                                    </>
                                )

                            }
                            {
                                data.year && (
                                    <>
                                        <FiberManualRecordIcon style={{ fontSize: '10px', marginLeft: '5px' }} />
                                        <span style={{ marginLeft: '2px', fontSize: '14px', fontWeight: 'bold' }}>{data.year}</span>
                                    </>
                                )
                            }

                        </Typography>
                        <Typography sx={{ mt: 1, display: 'flex', flexDirection: 'row' }}>
                            {
                                data.full_location && (
                                    <>
                                        <LocationOnIcon fontSize='small' />
                                        <span style={{ marginLeft: '2px', fontSize: '14px', fontWeight: 'bold' }}>{data.full_location} </span>
                                    </>
                                )
                            }
                        </Typography>
                        {
                            (currentUser?.loggedIn === true && isAdmin == "true") && (
                                <Typography sx={{ mt: 1, display: 'flex', flexDirection: 'row' }}>
                                    <RemoveRedEyeIcon fontSize='small' />
                                    <span style={{ marginLeft: '2px', fontSize: '14px', fontWeight: 'bold' }}>{data.total_clicks} </span>
                                </Typography>
                            )
                        }
                    </Box>
                </Grid>
                <Grid item xs={0} sm={0} md={1} lg={1} padding={1} >
                    <Divider variant="middle" orientation='vertical' />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={2} >
                    <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                        <Typography>
                            <span style={{ marginLeft: '2px', fontSize: '14px', fontWeight: 'bold' }}>{data.username}</span>
                        </Typography>
                        {
                            data.user_logo && (
                                <Typography>
                                    <img src={`${process.env.REACT_APP_API_BASE_URL_FILE}/profiles/${data.user_logo}`} width='120' height='100' />
                                </Typography>
                            )
                        }
                    </Box>
                </Grid>
            </Grid>
        </Card>
    )
}
export default CustomCard
