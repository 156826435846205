import React from 'react';
import Home from './views/Home/Home'
const CarDetails = React.lazy(() => import("./views/CarDetails/CarDetails"));
const Confirmation = React.lazy(() => import("./views/Login/Confirmation"));
const MyCars = React.lazy(() => import("./views/MyCars/MyCars"));
const ForgotPassword = React.lazy(() => import("./views/ForgotPassword/ForgotPassword"));
const Support = React.lazy(() => import("./views/Support/Support"));
const Privacy = React.lazy(() => import("./views/Privacy/Privacy"));

const routes = [
    { path: '/', exact: true, component: <Home /> },
    { path: "*", exact: true, component: <Home /> },
    { path: "/Details/:id", exact: true, component: <CarDetails /> },
    { path: "/Confirmation", exact: true, component: <Confirmation /> },
    { path: "/Support", exact: true, component: <Support /> },
    { path: "/PrivacyPolicy", exact: true, component: <Privacy /> },
    { path: "/ForgotPassword/:encryptedID/:randomString", exact: true, name: "/ForgotPassword", component: <ForgotPassword /> },

];
export default routes