import React, { useEffect, useRef } from 'react';
export const getYears = () => {
    var years = [];
    const currentYear = new Date().getFullYear();

    for (let i = 1880; i <= (currentYear + 1); i++) {
        years.push(
            {
                id: i, name: i
            }
        )
    }
    return years.reverse();
}